import { Component } from '@angular/core';
import { CvService } from './cv.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  data: any;
  showMoreInfo: boolean = false;
  constructor(private service: CvService)
  {
    this.service.getExperiences().subscribe(x => {
      var json = x;
      this.data = json;
    });
  }
  title = 'cv-client';
}
